import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import YouTube from 'react-youtube'

function YTPlayer({videoItem}) {
    const opts = {
        height: '350',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };

      const videoUrl="https://www.youtube.com/embed/"+videoItem?.videoId+"?autoplay=1&origin=http://localhost:3000"

      
  return (
    <div className='p-5'>
        <h2 className='font-bold text-[25px]'>{videoItem.title}</h2>
        <div className='flex gap-3 mt-2'>
            {videoItem?.tags?.map((item,index)=>(
                <div>
                   <h5 className='text-[8px] bg-blue-500 text-white
                   px-2 rounded-sm pb-1 pt-1 cursor-pointer'>#{item.attributes.name}</h5>
                </div>
            ))}
            </div>
            <div className='mt-3'>
            {/* <YouTube videoId={videoItem.videoId}
            style={{borderRadius:10}} 
            opts={opts} /> */}
            <iframe id="ytplayer" type="text/html" width="100%" height="360"
  src={videoUrl} 
  frameborder="0"></iframe>

            </div>

           <div className='p-4 bg-white mt-4 rounded-md'>
            <h2 className='font-bold text-[20px] mb-2'>About this course</h2>
            <ReactMarkdown className='text-[14px] text-gray-600'>
                {videoItem?.description}
            </ReactMarkdown>
            </div> 
    </div>
  )
}

export default YTPlayer