import React from 'react'
import VideoGridList from '../HomePageComponents/VideoGridList'

function RelatedVideoList() {
  return (
    <div>
        <VideoGridList isRecommeded={true} />
    </div>
  )
}

export default RelatedVideoList