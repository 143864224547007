import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import RelatedVideoList from '../Components/PlayVIdeoComponents/RelatedVideoList';
import YTPlayer from '../Components/PlayVIdeoComponents/YTPlayer';

function PlayVideo() {

    
    const location = useLocation()
    const [videoItem,setVideoItem]=useState([]);
    useEffect(()=>{
        console.log(location?.state)
        setVideoItem(location?.state?.item)
    },[])
  return (
    <div className='grid grid-cols-1 md:grid-cols-2'>
        <YTPlayer videoItem={videoItem} />
        <RelatedVideoList onVideoClick={(videoItem)=>setVideoItem(videoItem)}/>
    </div>
  )
}

export default PlayVideo