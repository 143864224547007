import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <div className='flex justify-between p-3 items-center bg-white'>
        <div className='flex gap-7 items-center'>
            <img src={require('./../Assets/logo.png')} className="w-[35px] rounded-full"/>
            <ul className='flex gap-4'>
                <li className={`cursor-pointer hover:bg-red-500 px-3 py-1 pb-2
                 rounded-full hover:text-white active:bg-red-500
                  active:text-white`} >
                  <Link to={'/'} >Home</Link>
                 </li>
                <li className='cursor-pointer hover:bg-red-500 px-3 py-1 pb-2
                 rounded-full hover:text-white'>Course</li>
                <li className='cursor-pointer hover:bg-red-500 px-3 py-1 pb-2
                 rounded-full hover:text-white'>Videos</li>
            </ul>
        </div>
        <div>
        <img src={require('./../Assets/youtube.png')} className="w-[35px]" />

        </div>
    </div>
  )
}

export default Header