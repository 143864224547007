import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useNavigation } from 'react-router-dom';
import GlobalApi from '../../Services/GlobalApi';
import VideoCard from './VideoCard';

function VideoGridList({isRecommeded=false,searchInput}) {
   
    const [videoList,setVideoList]=useState([])
    const navigate = useNavigate()
    useEffect(()=>{
       console.log(searchInput)  
      GlobalApi.getAllVideoList.then((resp)=>{
     
        const result=resp.data.data.map((item)=>(
            {
                id:item.id,
                title:item.attributes.title,
                description:item.attributes.description,
                image:item.attributes.image.data.attributes.url,
                tags:item.attributes.tags.data,
                videoId:item.attributes.videoUrl
            }
        ));
        
        setVideoList(result)
        
      })
    },[searchInput])
  return (
   <div>
   {!isRecommeded? <div className='mt-8 px-4 grid grid-cols-2 gap-4 md:grid-cols-3
     lg:grid-cols-4 justify-items-center
    md:px-10 lg:px-16'>
        {videoList.map((item)=>(
            <div key={item.id} onClick={()=>navigate('play-video',{ state:{item:item}})}>
                <VideoCard videoItem={item} />
            </div>
        ))}
    </div>:
    <div className='mt-8 md:mt-24 '>
       <h4 className='mx-4 '>Recommeded Videos</h4>
      <div className='mt-2 px-4 grid grid-cols-2 justify-items-center
    md:px-4 gap-4'>
     
        {videoList.map((item,index)=>index<4&&(
            <div key={item.id} onClick={()=>navigate('play-video',{ state:{item:item}})}>
                <VideoCard videoItem={item} />
            </div>
        ))}
    </div>
      </div>}
    </div>
  )
}

export default VideoGridList