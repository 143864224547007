import React, { useState } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import SearchSection from '../Components/HomePageComponents/SearchSection'
import VideoGridList from '../Components/HomePageComponents/VideoGridList'

function Home() {
  const [searchText,setSearchText]=useState();
  return (
    <div>
        
        <SearchSection searchInput={(text)=>setSearchText(text)}/>
        <VideoGridList searchInput={searchText} /> 
      
    </div>
  ) 
}

export default Home