import React from 'react'
import { HiMagnifyingGlass } from "react-icons/hi2";
function SearchSection({searchInput}) {
    const searchButtontags=[
        {
            id:1,
            tag:'React'
        },
        {
            id:2,
            tag:'Angular'
        },
        {
            id:3,
            tag:'Tailwind Css'
        },
        {
            id:4,
            tag:'ChatGpt'
        },
    ]
  return (
    <div className='text-center mt-3 flex flex-col items-center'>
        <h1
  class="font-extrabold text-transparent text-6xl
   bg-clip-text bg-gradient-to-r from-pink-600 to-blue-900"
>
  VIDEOS
</h1>
        <h2 className='text-[15px] text-gray-500 mt-2'>challenging multi-step experiences with quizzes and progress-tracking</h2>
        <div className='mt-3 justify-center flex bg-white p-2 px-3 items-center rounded-md w-[50%] md:w-[35%] lg:w-[30%]'>
            <input type="text" placeholder='Search' onChange={(text)=>searchInput(text.target.value)}
            className=' px-3 w-[95%] outline-none'/>
            <HiMagnifyingGlass/>
        </div>
        <div>
            <ul className='flex gap-3 mt-4'>
                {searchButtontags.map((item)=>(
                    <li className='bg-white px-3 pb-1
                     rounded-md cursor-pointer text-[14px]
                     hover:bg-red-400 hover:text-white hover:scale-110
                     transition-all duration-300 hover:font-bold'>{item.tag}</li>
                ))}
            </ul>
        </div>


    </div>
  )
}

export default SearchSection