import React from 'react'

function Footer() {
  return (
    <div className='text-center mt-7 mb-7'>
        <p className='text-[14px] text-gray-500'>Need help? Email hello@tubeguruji.com</p>
        <p className='text-[14px] text-gray-500'>Copyright © 2023 Tubeguruji</p>
       

    </div>
  )
}

export default Footer