import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import Footer from './Components/Footer';
import Header from './Components/Header';
import { Route, Router, Routes } from 'react-router-dom';
import PlayVideo from './Pages/PlayVideo';

function App() {
  return (
    // <Router>
    <div>
      <Header/>
      <div className='p-5'>
         
         
            <Routes>
            
                 <Route path='/'  element={<Home/>}></Route>
                 <Route path='/play-video'  element={<PlayVideo/>}></Route>

                 {/* <Route exact path='/about' element={< About />}></Route>
                 <Route exact path='/contact' element={< Contact />}></Route> */}
          </Routes>
         
        
      {/* <Home/> */}
      </div>
        
      <Footer/>
    </div>
    // </Router>
  );
}

export default App;
